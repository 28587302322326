<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>商家首页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 查询 -->
      <div style="display: flex; align-items: center; flex-wrap: wrap;">
        <label style="margin: 10px;" for="">停车场：
          <el-select v-model="query.pkId" filterable placeholder="请选择" @change="cxChange" style="width:200px;" size="small">
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id">
            </el-option>
          </el-select>
        </label>
      </div>
      <div class="yue">
        <div class="yue-s"><i class="el-icon-coin"></i></div>
        <div class="yue-x">
          <h2>可用余额</h2>
          <p>可用余额：{{shopzonge ? shopzonge : 0}}元<el-button style="margin-left: 10px" type="primary" size="small" @click="goback">去查看</el-button></p>
        </div>
      </div>
      <!-- 表格 -->
      <transition appear tag="div">
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column header-align="left" align="left" prop="couponName" label="优惠券名称"></el-table-column>
        <el-table-column header-align="left" align="left" prop="quality" label="创建数量"></el-table-column>
        <el-table-column header-align="left" align="left" prop="remainNum" label="剩余数量"></el-table-column>
      </el-table>
      </transition>
    </el-card>
  </div>
</template>

<script>
import { findPkInfoSelect } from '@/api/Parkinglot.js'
import { shopInfoPk, shopCoupon } from '@/api/coupon.js'
export default {
  data () {
    return {
      tableData: [],
      query: {
        pkId: undefined
      },
      querys: {
        pkIds: []
      },
      shopzonge: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    cxChange() {
      this.shopCoupon()
      this.shopInfoPk()
    },
    goback() {
      this.$router.push({ path: 'Quotaview' })
    },
    findPkInfoSelect() { // 停车场下拉框
      findPkInfoSelect({allRecord: 0}).then(res => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pkId = this.PkInfoSelect[0].id
          this.shopInfoPk()
          this.shopCoupon()
        }
      })
    },
    shopInfoPk() { // 金额
      this.querys.pkIds[0] = this.query.pkId
      shopInfoPk(this.querys).then(res => {
        if (res.data.data.list.length) {
          this.shopzonge = res.data.data.list[0].totalCouponMoney
        }
      })
    },
    shopCoupon() { // 列表
      shopCoupon(this.query).then(res => {
        this.tableData = res.data.data.list
      })
    },
  }
}

</script>
<style lang="scss" scoped>
.yue {
  display: flex;
  align-items: center;
  .yue-s {
    width: 70px;
    height: 70px;
    background-color: #f1f1f1;
    text-align: center;
    font-size: 50px;
    margin-right: 15px;
  }
  .yue-x {
    display: flex;
    flex-direction: column;
    h2, p {
      margin: 5px 0;
    }
  }
}
</style>
